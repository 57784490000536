.loading {
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.9);
  top: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: white;

  h2 {
    text-align: center;
    padding: 0 1rem;
  }
}

.loading-thumbnail {
  background-color: rgba(0, 0, 0, 0.5) !important;
  height: 75% !important;
  z-index: 1 !important;
  position: absolute;
}
